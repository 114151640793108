// Generated by Framer (38f2e7f)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, getLoadingLazyAtYPosition, Image, Link, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const serializationHash = "framer-mPSJY"

const variantClassNames = {wgjIjymAZ: "framer-v-1jazwte"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const getProps = ({height, id, link, width, ...props}) => { return {...props, AfdHcWyNs: link ?? props.AfdHcWyNs} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;link?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, AfdHcWyNs, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({defaultVariant: "wgjIjymAZ", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Link href={AfdHcWyNs}><Image {...restProps} {...gestureHandlers} as={"a"} background={{alt: "", fit: "fit", intrinsicHeight: 24, intrinsicWidth: 134, loading: getLoadingLazyAtYPosition((componentViewport?.y || 0)), pixelHeight: 261, pixelWidth: 292, positionX: "right", positionY: "center", src: "https://framerusercontent.com/images/FR03Cc0ovrclw9hD7pcC4JFcQfo.png"}} className={`${cx(serializationHash, ...sharedStyleClassNames, "framer-1jazwte", className, classNames)} framer-1wxg2ni`} data-framer-name={"Primary"} layoutDependency={layoutDependency} layoutId={"wgjIjymAZ"} ref={ref ?? ref1} style={{...style}}/></Link></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-mPSJY.framer-1wxg2ni, .framer-mPSJY .framer-1wxg2ni { display: block; }", ".framer-mPSJY.framer-1jazwte { height: 92px; overflow: visible; position: relative; text-decoration: none; width: 95px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 92
 * @framerIntrinsicWidth 95
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"AfdHcWyNs":"link"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerqXfVG6fSe: React.ComponentType<Props> = withCSS(Component, css, "framer-mPSJY") as typeof Component;
export default FramerqXfVG6fSe;

FramerqXfVG6fSe.displayName = "Logo";

FramerqXfVG6fSe.defaultProps = {height: 92, width: 95};

addPropertyControls(FramerqXfVG6fSe, {AfdHcWyNs: {title: "Link", type: ControlType.Link}} as any)

addFonts(FramerqXfVG6fSe, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})